.terminal {
    width: 650px;
    position: absolute;
    top: 10%;
    left: 4%;
}

.terminal-header {
    background-color: #2e3440;
    text-align: center;
    padding: 3px 0px;
}

.terminal-title {
    vertical-align: super;
}

.terminal-body {
    background-color: rgb(67, 76, 94, 0.8);
    height: 300px;
    padding: 10px;
}
.terminal-user {
    color:#eceff4;
    font-size: 13px;
    margin-right: 5px;
}

.terminal-text {
    font-size: 13px;
    font-weight: 400;
    animation: typing 3s steps(40, end), blink-caret 0.75s step-end infinite;
}

#cursor {
    border-right: 0.15em solid orange;
    height: 1.2em;
    width: 0.15em;
    animation: blink-caret 0.75s step-end infinite;
}

@keyframes typing {
    from {
        width: 0;
    }

    to {
        width: 100%;
    }
}

@keyframes blink-caret {

    from,
    to {
        border-color: transparent
    }

    50% {
        border-color: orange;
    }
}