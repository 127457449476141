* {
  margin: 0;
  padding: 0;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

/* vars */
:root { 
  --bg-color: #282c34;
  --primary-color: #fff;
  --container-width-lg: 75%;
  --container-width-md: 85%;
  --container-width-sm: 90%;
}

/* scrollbar */
html {
  scroll-behavior: smooth;
  background-repeat: no-repeat;
  background-size: cover;
}

::-webkit-scrollbar {
  display: none;
}

/* general style */
body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  background-image: url("./assets/pixel-art-portfolio.jpg");
  background-color: var(--bg-color);
  color: var(--primary-color);
  margin: 0;
}

.container {
  width: var(--container-width-lg);
  margin: 0 auto;
}

/* media queries - medium devices */
@media screen and (max-width: 1024px) {
  .container {
    width: var(--container-width-md);
  }
}

/* media queries - small devices */
@media screen and (max-width: 600px) {
  .container {
    width: var(--container-width-sm);
  }
}

/* useful styles - font-styles */
.small-text {
  font-size: 13px;
}

.bold-text {
  font-weight: 700;
}